@import "../variables/variable";

.tabs-header {
  border: none;
  width: 100%;
  margin-bottom: 15px;
  & .nav-item {
    margin: 0 4px;
    font-size: 13px;
    border: none;
    text-align: center;
    color: $text-link-color;
    &.active {
      border-bottom: 2px solid $primary-color;
    }
  }
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-space-between.to-right {
  justify-content: flex-end;
}
