@import "../variables/variable";

.title-lampiran {
  font-size: 2rem;
  text-align: center !important;
  margin-top: 10%;
  color: $link-active;
  font-weight: bold;
}

.content-lampiran {
  background-color: #fdfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  // position: relative;
}

// .container-lampiran {
//   transform: translateY(-100%);
// }

.container-lampiran p {
  color: #3d3f56;
  text-align: center;
}

.drop-container-lampiran {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 200px;
  border: 1px solid $link-active;
  padding: 40px;
  border-radius: 10px;
}

.upload-icon-lampiran {
  background: url('/assets/img/icon/lampiran.svg') no-repeat center center;
  background-size: contain;
  background-position: center;
  text-align: center;
  margin: 0 auto 10px auto;
  padding-top: 30px;
  font-size: 50px;
  width: 150px;
  height: 50px;
}

.drop-message-lampiran {
  text-align: center;
  color: #3d3f56;
}

// lampiran css

.nav-item {
  & .my-btn-tabs {
    font-size: 15px;
    background-color: transparent;
    border-radius: 0;
    color: $white-dark;
    margin: 0 2px;
    &.active {
      background-color: transparent;
      color: $text-color-dark;
      border-bottom: 2px solid $link-active;
    }
  }
}

// css mobile view begin
.content {
  margin-top: 20px;
  margin-left: 0px;
  padding: 12px;
  width: 100%;

  & .content-wrapper {
    background-color: $white;
    padding: 10px;
    & .content-title {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .content-filter {
        width: 100%;
        & label {
          width: 100%;
        }
        & .select-class {
          width: 100%;
        }
      }
    }
    & .content-body {
      // overflow-y: scroll;
      position: relative;
      overflow-x: auto;
      width: inherit;
      & .info-board {
        background-color: #f8f6f6;
        padding: 5px 0px;
        & .item-info-board {
          list-style: none;
          padding-left: 0;
          text-align: center;
          font-size: 13px;
          & .title-info-board {
            font-weight: bold;
            padding: 10px 0;
          }
          & .sub-item-info-board {
            list-style: none;
            padding: 0 10px;
            & li {
              border-bottom: 1px solid $white-dark;
              & .badge {
                padding: 0 8px;
                border-radius: 10px;
                font-weight: 400;
              }
              & a {
                text-decoration: none;
                color: $primary-color;
              }
              .classik-date {
                font-style: normal;
                font-size: 10px;
                color: #8f8e8e;
              }
            }
          }
        }
      }
      & .text-flex-row-between {
        display: flex;
        justify-content: center;

        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
      }
      & .text-flex-column {
        display: flex;
        flex-direction: column;
        & span:nth-child(2) {
          font-size: small;
        }
      }
      & .text-flex-row {
        display: flex;
        flex-direction: column;
        & .img-top-text {
          margin-right: 10px;
        }
      }
      & .text-flex-row-between {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
  }
  .list-parent-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
  }
  .nested-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 30px;
  }
  .badge-status {
    font-size: 12px;
    border-radius: 2px;
    padding: 4px 5px;
    font-style: normal;
  }
  .loading-submit {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.37);
  }
  .item-content-table {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    & .content-table-card {
      display: flex;
      flex-direction: row;
      // background-color: darksalmon;
      & .content-table-images {
        align-self: flex-start;
        background-color: royalblue;
        width: 100px;
        // height: 100px;
        border-radius: 10px;
      }
      & .content-table-text {
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        & .content-table-text-row {
          margin-bottom: 15px;
          & .label-table {
            width: 150px;
            display: inline-block;
          }
          .badge-table {
            padding: 3px 8px;
            border-radius: 20px;
            color: #fff;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.ck-content {
  min-height: 25vh;
}

.view-print__content {
  padding: 10px;
  min-height: 77vh;
  width: inherit;
  & .content-wrapper {
    background-color: $white;
    padding: 15px;
    & .content-title {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $white-dark;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & .content-filter {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        & label {
          width: 7%;
        }
        & .select-class {
          width: 60%;
        }
      }
    }
    & .content-body {
      position: relative;
      overflow-x: unset;
      width: inherit;
      & table {
        font-size: 14px;
        font-weight: 400;
        & thead {
          background-color: $white-dark;
          font-weight: 500;
          & td {
            text-align: left;
            vertical-align: middle;
          }
        }

        & tr {
          & td {
            font-size: 13px;
            & input {
              width: 17px;
              height: 17px;
            }
            & .link-table {
              text-decoration: none;
              color: $primary-color;
            }
          }
        }
      }
      & .text-flex-row-between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: flex-start;
      }
      & .text-flex-row {
        display: flex;
        flex-direction: row;
        & .img-top-text {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .content {
    margin-left: 250px;
    margin-top: 9vh;
    padding: 10px;
    min-height: 77vh;
    width: inherit;
    & .content-wrapper {
      background-color: $white;
      padding: 15px;
      & .content-title {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $white-dark;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & .content-filter {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          & label {
            width: 7%;
          }
          & .select-class {
            width: 60%;
          }
        }
      }
      & .content-body {
        position: relative;
        overflow-x: unset;
        width: inherit;
        & table {
          font-size: 14px;
          font-weight: 400;
          & thead {
            background-color: $white-dark;
            font-weight: 500;
            & td {
              text-align: left;
              vertical-align: middle;
            }
          }

          & tr {
            & td {
              font-size: 13px;
              & input {
                width: 17px;
                height: 17px;
              }
              & .link-table {
                text-decoration: none;
                color: $primary-color;
              }
            }
          }
        }
        & .info-board {
          background-color: #f8f6f6;
          padding: 10px 5px;
          height: inherit;
          font-family: "Nunito", sans-serif;
          & .item-info-board {
            list-style: none;
            padding-left: 10px;
            text-align: left;
            font-size: 13px;
            & .title-info-board {
              font-weight: bold;
              padding: 5px 0;
            }
            & .sub-item-info-board {
              list-style: none;
              padding: 0px;
              & li {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 0px solid $white-dark;
                margin-bottom: 5px;
                & div {
                  margin-right: 15px;
                }
                & .badge {
                  padding: 5px 8px;
                  border-radius: 10px;
                  font-weight: 400;
                }
                & a {
                  text-decoration: none;
                  color: $primary-color;
                }
                .classik-date {
                  font-style: italic;
                  font-size: 10px;
                  color: #8f8e8e;
                }
              }
            }
          }
        }
        & .text-flex-row-between {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 15px;
          align-items: flex-start;
        }
        & .text-flex-row {
          display: flex;
          flex-direction: row;
          & .img-top-text {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .item-content-table {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    border-bottom: 0.5px solid #8f8e8e;
    & .content-table-card {
      display: flex;
      flex-direction: row;
      & .content-table-images {
        align-self: flex-start;
        background-color: royalblue;
        width: 100px;
        height: 100px;
        border-radius: 10px;
      }
      & .content-table-text {
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        & .content-table-text-row {
          margin-bottom: 15px;
          & .label-table {
            width: 150px;
            display: inline-block;
          }
          .badge-table {
            padding: 3px 8px;
            border-radius: 20px;
            color: #fff;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .input-date-style {
    position: relative;
    display: flex;
    margin: 0 5px;
    & .react-datepicker-wrapper {
      width: 100%;
    }
  }
  .tootletip-hover {
    position: relative;
    cursor: pointer;
    .tootletip {
      display: none;
    }
    &:hover {
      .tootletip {
        position: absolute;
        padding: 4px 10px;
        border-radius: 5px;
        background-color: rgba($color: $primary-color, $alpha: 0.9);
        color: $white;
        font-size: 12px;
        display: flex;
        top: -25px;
        left: 0;
        z-index: 10;
        &:hover {
          display: none;
        }
      }
    }
  }
}
