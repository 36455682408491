@import "../variables/variable";

.border-vertical-right {
  padding-right: 10px;
  border-right: 2px solid $white-dark;
}

.btn-default {
  color: $text-color-dark;
  &:hover {
    color: $text-color-dark;
  }
  &:focus {
    box-shadow: none;
  }
}

// mulai mobile view css

.sub-navbar {
  background-color: $white;
  height: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  align-items: center;
  padding: 10px;
  position: relative;
  justify-content: space-around;
  z-index: 80;
  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;

    box-shadow: 0 5px 12px $white-dark;
    z-index: -1;
  }
  & .label-form-sub-nav {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
  }
  & .input-form-sub-nav {
    display: flex;
    flex-direction: column;
    & .input-date-sub-nav {
      position: relative;
      display: flex;
      margin: 0 0 8px 0;
      & .react-datepicker-popper {
        z-index: 200;
      }
      & .react-datepicker-wrapper {
        & input {
          width: 280px;
          text-decoration: none;
          border: none;
          border-bottom: 1px $white-dark solid;
          height: 30px;
          font-weight: 300;
          background-color: transparent;
          padding: 3px 6px;
          &:focus {
            outline: none;
            border-bottom: 1px $white-dark solid;
          }
        }
      }
      // label
      & .icon-input-date-sub-nav {
        & .fa {
          position: absolute;
          color: $white-dark;
          font-size: 18px;
          top: 5px;
          right: 15px;
        }
      }
    }
    & .input-group {
      & .form-control {
        border: none;
        outline: none;
        border: 2px $white-dark solid;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: none;
        margin-right: 1px;
        background-color: transparent;
      }
    }
  }
}
.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
}

.top-fix {
  top: 0;
  position: fixed !important;
}

@media (min-width: 1024px) {
  .sub-navbar {
    position: absolute;
    margin-left: 250px;
    background-color: $white;
    // height: 57px;
    right: 0;
    left: 0;
    height: 8vh;
    width: auto;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    z-index: 1;
    &:after {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      box-shadow: 0 5px 12px $white-dark;
      z-index: -1;
    }
    & .label-form-sub-nav {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 2px;
    }
    & .input-form-sub-nav {
      border-left: 1px solid $white-dark;
      display: flex;
      flex-direction: row;
      & .input-date-sub-nav {
        position: relative;
        display: flex;
        width: 190px;
        margin: 0 5px;
        & .react-datepicker-wrapper {
          & input {
            width: 190px;
            text-decoration: none;
            border: none;
            border-bottom: 1px $white-dark solid;
            height: 40px;
            font-weight: 300;
            background-color: transparent;
            padding: 3px 6px;
            &:focus {
              outline: none;
              border-bottom: 1px $white-dark solid;
            }
          }
        }
        // label
        & .icon-input-date-sub-nav {
          & .fa {
            position: absolute;
            color: $white-dark;
            font-size: 18px;
            top: 10px;
            right: 15px;
          }
        }
      }
      & .input-group {
        width: 300px;
        & .form-control {
          border: none;
          outline: none;
          border: 2px $white-dark solid;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          box-shadow: none;
          margin-right: 1px;
          background-color: transparent;
        }
      }
    }
  }
  .sticky-nav {
    position: fixed;
    right: 0;
    // background-color: transparent;
    top: 0;
    left: 0;
    width: auto;
  }
}
