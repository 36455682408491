@import "../variables/variable";

.content-footer {
  & .paginate-table {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    // width: 100%;
    & .paginate-text {
      font-weight: 300;
      margin: 10px 0;
    }
    & .paginate {
      list-style: none;
      text-decoration: none;
      padding-left: 0;
      display: flex;
      // flex-direction: row;
      & li {
        & a {
          width: fit-content;
          padding: 0 7px;
          height: 25px;
          display: inline-block;
          margin: 0 4px;
          text-align: center;
          line-height: 23px;
          color: $primary-color;
        }
      }
      & .disable-page {
        cursor: default;
        // background-color: $white-dark;
        &:hover {
          color: $primary-color;
          // background-color: $white-dark;
        }
      }
    }
  }
}

@media (min-width: 1020px) {
  .content-footer {
    & .paginate-table {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & .paginate {
        list-style: none;
        text-decoration: none;
        padding-left: 0;
        display: flex;
        margin-top: 10px;
        // flex-direction: row;
        & li {
          & a {
            padding: 0 12px;
            height: 32px;
            display: inline-block;
            margin: 0 5px;
            text-align: center;
            line-height: 30px;
            color: $primary-color;
          }
        }
        & .disable-page {
          cursor: default;
          // background-color: $white-dark;
          &:hover {
            color: $primary-color;
            // background-color: $white-dark;
          }
        }
      }
    }
  }
}
