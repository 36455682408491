@import "../variables/variable";

// .footer {
//   position: absolute;
//   z-index: -9999;
//   // margin: 0 15px;
//   text-align: center;
//   padding: 10px 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   color: #98a6ad;
// }

@media (min-width: 1024px) {
  .footer {
    // position: absolute;
    z-index: 999;
    margin-left: 250px;
    // width: 100vw;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 0;
    left: 0;
    right: 0;
    color: $white-hover;
    margin-right: 10px;
  }
}
