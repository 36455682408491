@import "../variables/variable";
.sidebar {
  display: none;
  position: fixed;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  padding-bottom: 50px;

  // &.show-sidebar {
  //   display: block;
  // }
  & .img-logo {
    width: 100%;
    font-size: 20px;
    height: 46px;
    padding: 7px;
    text-align: center;
    position: relative;
    & i {
      position: absolute;
      z-index: 102;
      left: 215px;
      font-size: 25px;
      color: $primary-color;
      top: 11px;
    }
    span {
      h3 {
        font-size: 18px;
      }
    }
  }
  & .wrapper-side-link {
    padding: 20px 0;
    color: $white-dark;
    height: 90%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: red($color: #000000);
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c2bebe;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #c2bebe;
      border-radius: 10px;
    }
    & .text-link-menu {
      margin: 5px 10px;
    }
    & .side-link {
      list-style: none;
      text-decoration: none;
      margin-left: 0;
      padding-left: 0;
      font-size: 14px;
      font-weight: 400;
      & .side-link-item {
        // margin-bottom: 15px;
        & .link-item {
          text-decoration: none;
          color: #818d97;
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          padding: 10px 0 10px 10px;
          &:hover {
            color: $white;
            background-color: $link-side-bgcolor;
          }
          & span {
            font-weight: 500;
            margin-left: 10px;
          }
          & i:last-child {
            margin-left: auto;
            margin-right: 10px;
          }
        }
        & .active {
          color: $white;
          background-color: $link-side-bgcolor;
          & + .sub-side-link-item {
            display: inline-block;
            // width: 100%;
          }
          .fa-chevron-down {
            transform: rotate(180deg);
          }
          &:hover {
            color: $white;
          }
        }
        & .sub-side-link-item {
          list-style: none;
          padding-left: 10px;
          width: 100%;
          display: none;
          & li {
            & a {
              height: 100%;
              width: 100%;
              display: inline-block;
              text-decoration: none;
              padding: 10px;
              color: #9fa4a8;
              &:hover {
                color: $link-side-bgcolor;
              }
              &.active {
                color: $link-side-bgcolor;
                background-color: transparent;
              }
            }
          }
        }
        // .close-link {
        //   display: none;
        // }
      }
    }
  }
}

@media (min-width: 1024px) {
  .sidebar {
    display: block;
    position: fixed;
    top: 0;
    width: 250px;
    height: 100%;
    z-index: 100;
    background-color: #fff;
    & .img-logo {
      width: 100%;
      font-size: 20px;
      // background-color: rosybrown;
      height: 57px;
      padding: 14px;
      text-align: center;
      // align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: row;
      & i {
        position: absolute;
        display: none;
        left: 0;
        color: black;
        z-index: 9;
        // opacity: 0;
      }
      span {
        h3 {
          font-size: 26px;
        }
      }
    }
    & .wrapper-side-link {
      padding: 20px 0;
      overflow-y: auto;
      height: 80%;
      color: $white-dark;
      // background-color: salmon;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: red($color: #000000);
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c2bebe;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #c2bebe;
        border-radius: 10px;
      }
      & .text-link-menu {
        margin: 5px 10px;
      }
      & .side-link {
        list-style: none;
        text-decoration: none;
        margin-left: 0;
        padding-left: 0;
        font-size: 14px;
        font-weight: 400;
        // background-color: greenyellow;

        & .side-link-item {
          // margin-bottom: 15px;
          & .link-item {
            text-decoration: none;
            color: #818d97;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 10px 0 10px 10px;
            &:hover {
              color: $white;
              background-color: $link-side-bgcolor;
            }
            & span {
              font-weight: 500;
              margin-left: 10px;
              // transition: all 0.1s;
            }
            i:last-child {
              margin-left: auto;
              margin-right: 10px;
              font-size: 12px;
            }
          }
          & .active {
            color: $white;
            background-color: $link-side-bgcolor;
            & + .sub-side-link-item {
              display: inline-block;
              // width: 100%;
            }
            .fa-chevron-down {
              transform: rotate(180deg);
            }
          }
          & .sub-side-link-item {
            list-style: none;
            padding-left: 10px;
            display: none;
            & li {
              & a {
                height: 100%;
                width: 100%;
                display: inline-block;
                text-decoration: none;
                padding: 10px;
                color: #818d97;
                &:hover {
                  color: $link-active;
                }
                &.active {
                  background-color: #fff;
                  color: $link-active;
                  display: inline-block;
                }
              }
            }
          }
          .open {
            display: inline-block;
            width: 200px;
            background-color: $white;
            border-left: 3px solid $primary-color;
          }
        }
      }
    }
  }
  .mini-sidebar {
    width: 80px;
    .side-link-item {
      display: flex;
      .link-item {
        padding: 10px 0 !important;
        justify-content: center;
        flex-direction: row;
        span {
          display: none;
          // transition: all 0.3s;
        }
        i {
          font-size: 25px;
        }
        i:last-child {
          display: none;
        }
      }
      .sub-side-link-item {
        background-color: red;
        position: absolute;
        left: 80px;
        width: 350px !important;
        background-color: $white;
        border-left: 3px solid $primary-color;
      }
      // .open {
      //   position: absolute;
      //   left: 80px;
      //   width: 350px;
      //   background-color: $white;
      //   border-left: 3px solid $primary-color;
      // }
    }
  }
}

.child-link {
  display: flex;
  justify-content: space-between;
}
