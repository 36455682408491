@import "../variables/variable";

.modal-lg {
  max-width: 1200px;
}

.header-modal {
  background-color: $primary-color;
  height: 50px;
  overflow: hidden;
  color: $white;

  & .title-modal {
    &.h4 {
      align-self: center;
      font-size: 15px;
    }
  }

  & .close {
    font-size: 18px;
    align-self: center;
    justify-content: center;
    text-align: center;
  }
}

.modal-body {
  padding: 0;

  & .content-modal {
    // background-color: salmon;
    padding-left: 0;
    padding-right: 0;
    margin: 10px 0;

    & .nav,
    .tabs-header-modal {
      // background-color: salmon;
      display: table;
      width: 100%;

      & .nav-item {
        display: table-cell;
        vertical-align: center;
        text-align: center;
        border: none;
        color: $text-link-color;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        margin-bottom: 0;

        &.active {
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }

  & .reset-margin-row {
    margin-left: 0;
    margin-right: 0;
  }

  & .space-table {
    border-spacing: 5px;
  }

  & .title-tab-second {
    background-color: #d1bea9;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid #d1bea9;
  }

  & .no-border-bottom {
    border-bottom: none;
    border-right: 1px solid #eaeaea;
  }

  & .text-right {
    text-align: right;
  }

  & .table {
    margin-bottom: 0;

    & td,
    th {
      font-size: 13px;
    }
  }

  .text-row {
    display: flex;
    flex-direction: row;
  }

  .space-around {
    justify-content: space-around;
  }

  .flex-start {
    justify-content: flex-start;
  }

  .no-list {
    list-style: none;
  }

  .display-table {
    display: table;
    border-spacing: 5px;
    padding: 0 0;
    width: 100%;
  }

  .display-table>li {
    display: table-cell;

    // border: 1px solid #3f3d56;
    & .fa {
      font-size: medium;
      color: #3f3d56;
      margin: 0 4px;
    }

    & ul {
      padding-left: 10px;

      & li {
        margin-bottom: 4px;
        padding-left: 0px;

        & .fa {
          font-size: small;
          color: #3f3d56;
          margin: 0 4px;
        }
      }
    }
  }

  .title-text {
    text-align: center;
    font-weight: bold;
    border: 1px solid #3f3d56;
    margin: 10px 0;
  }
}

.modal-backdrop {
  z-index: 1050;
}

.no-style-ul {
  list-style: none;
}

// printing style
.kop-surat {
  width: inherit;
  // background-color: salmon;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .img-title-surat {
    display: flex;
    flex-direction: row;

    .text-flex-column {
      display: flex;
      flex-direction: column;

      & span:nth-child(2) {
        font-size: small;
      }
    }
  }
}

.loading-bg {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(251, 251, 251, 0.656);
}

.table-list {
  position: relative;

  .loading-bg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99.8%;
    height: 90%;
    background-color: red;
    // background-color: rgba(209, 209, 209, 0.553);
  }
}