@import "./variables/variable";

.wrapper-card-info {
  // border: 2px solid black;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  border-radius: 10px;
  margin: 10px;
  & .title-card {
    margin-top: 10px;
  }
  & .info-card {
    display: flex;
    width: 100%;
    justify-content: space-around;
    // background-color: saddlebrown;
    align-items: center;
    height: 100px;
    & .info-item {
      // background-color: darkcyan;
      text-align: center;
    }
    & .info-images {
      width: 100%;
    }
  }
}
