@import "../variables/variable";

.container-login {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  background-color: $primary-color;

  header {
    z-index: 12;
    width: 100vw;
    color: $white;
  }

  header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 10px 30px;

    img {
      width: 30vw;
    }

    .fa-bars {
      color: $white;
      font-size: 25px;
    }

    .menu-header {
      position: absolute;
      z-index: 13;
      width: 50vw;
      background-color: $white;
      border-radius: 5px;
      right: 7%;
      top: 70%;
      height: fit-content;
      bottom: 0;
      display: none;

      ul {
        height: fit-content;
        list-style: none;
        padding-left: 8px;
        margin-bottom: 0;
        width: 100%;

        li {
          padding: 5px;

          a {
            color: #121d50;
          }
        }
      }

      &.show {
        display: flex;
      }
    }
  }

  .display-block {
    display: block !important;
  }

  .content {
    padding: 0;
    width: 100vw;
    // height: 81vh;
    // display: none;
    align-items: center;
    justify-content: center;
    margin: auto;

    .logo {
      margin-bottom: 30px;
      text-align: center;

      img {
        width: 60vw;
      }
    }

    .tab-header-login {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      margin: 0;
      justify-content: space-evenly;
      text-align: center;

      li {
        width: 100%;
        // border-bottom: 4px solid transparent;
      }

      li>a {
        padding: 15px 0;
        text-align: center;
        color: white;
        width: 100%;
        display: inline-block;

        &.active {
          border-bottom: 4px solid #075eeb;
        }
      }
    }

    .form-wrapper {
      width: inherit;
      padding: 40px 20px;
      background-color: white;

      .title {
        text-align: left;
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        margin: 10px 0 20px;
        color: #323F4B;

        span:first-child {
          font-size: 3rem;
        }

        span:last-child {
          font-size: 1.3rem;
        }
      }

      .input-wrapper {
        color: #323F4B;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 10px 0;
        // color: $white;

        & span:nth-child(1) {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }

        & span:nth-child(2) {
          font-size: 1rem;
        }

        label {
          margin-bottom: 6px;
          margin-left: 11px;
          font-size: 1.1rem;
          font-weight: bold;
        }

        input {
          height: 50px;
          font-size: 1.2rem;
          border-radius: 5px;
          padding: 0 15px 0 45px;
          border: 1px solid #7B8794;
          background-color: transparent;
          outline: none;
        }

        .fa {
          position: absolute;
          bottom: 14px;
          left: 13px;
          font-size: 1.5rem;
          color: $white-hover;
        }

        .eye-password {
          position: absolute;
          right: 11px !important;
          bottom: 12px;
        }

        .eye-password .fa {
          position: static !important;
        }
      }

      .btn-wrapper {
        margin: 15px 0;

        button {
          width: 100%;
          padding: 10px 30px;
          border: none;
          outline: none;
          background-color: #075eeb;
          color: $white;
          cursor: pointer;
          border-radius: 30px;
        }

        :disabled {
          background-color: #5e93eaf9;
          cursor: not-allowed;
        }
      }

      .capcha-wrapper {
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span.error {
          color: rgb(250, 66, 66);
        }
      }

      .option-wrapper {
        display: flex;
        padding: 0 10px;
        justify-content: space-between;

        a {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  .right-content {
    background-color: white;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100vw;
    // height: 84vh;
    text-align: center;
    // background-image: url('/assets/img/asset-3.svg');
    background-repeat: no-repeat;
    background-size: contain;

    .tabs-header {
      color: #323F4B;
    }

    img {
      margin: 4vh 0;
      width: 70vw;
    }

    .download {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        color: $white;
        font-size: 14px;
      }

      img {
        width: 40%;
        margin: 6px 0;
        cursor: pointer;
      }
    }

    .result-wrapper {
      // height: 100%;
      width: 100%;

      // padding: 0px 30px 0px 10px;
      // overflow: scroll;
      // margin: 10% 0;
      hr {
        background-color: #eaeaea;
      }

      .tabs-header {
        border: none;
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 10px;

        & .nav-item {
          margin: 0 4px;
          font-size: 15px;
          border: none;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #323F4B;
          display: flex;
          flex-wrap: wrap;
          word-wrap: break-word;
          background-color: transparent;

          &.active {
            border-bottom: 3px solid #fff;
          }
        }
      }

      .wrapper-table {
        min-height: 35vh;
        width: 100%;
        // min-height: 65vh;
        overflow: scroll;

        table {
          width: 700px;
          max-width: none;
          background-color: #fff;
          color: $text-link-color;
          margin-bottom: 0;

          tr {
            td {
              &:first-child {
                width: 150px;
              }
            }

            font-size: .8rem;
            text-align: left;
          }
        }
      }
    }
  }

  // footer {
  //   position: relative;
  //   // background-color: red;
  //   // height: 16vh;
  //   // bottom: 0;
  //   margin-top: 15px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   color: $white;

  //   .footer-logo {
  //     width: 9vw;
  //     margin-right: 5px;
  //   }

  .text-footer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;

    & span {
      text-align: center;
      font-size: .9rem;
      color: white;
    }
  }

}

@media (min-width: 1024px) {
  .container-login {
    display: flex;
    position: relative;
    background-color: $primary-color;
    color: $white;
    flex-direction: initial;

    header,
    footer {
      position: absolute;
      z-index: 100;
      display: flex;
      flex-direction: row;
      width: 100vw;
      padding: 0 30px;
      align-items: center;
      justify-content: left;
      // background-color: salmon;
    }

    header {
      height: 50px;
      justify-content: space-between;
      top: 20px;

      i {
        display: none;
      }

      img {
        width: 15vw;
        height: 6vh;
      }

      .menu-header {
        height: 100%;
        width: initial;
        background-color: transparent;
        display: flex;
        position: static;
        justify-content: flex-end;

        ul {
          display: flex;
          flex-direction: row;
          list-style: none;
          justify-content: center;
          align-items: center;
          height: 100%;

          li {
            margin: 5px 10px;

            a {
              // background-color: salmon;
              padding: 5px 15px;
              text-decoration: none;
              border-radius: 5px;
              color: $white;

              &:hover {
                background-color: $white;
                color: $primary-color;
              }
            }

            .active {
              background-color: $white;
              color: $primary-color;
            }
          }
        }
      }
    }

    footer {
      bottom: 20px;

      .footer-logo {
        width: 3vw;
      }

      .text-footer {
        margin: 0 20px 0 10px;
        display: flex;
        flex-direction: column;

        & span:nth-child(1) {
          padding-bottom: 0;
        }

        & span:nth-child(2) {
          font-size: 13px;
        }
      }

      .text-support {
        display: flex;
        flex-direction: row;
        align-items: center;

        & div:nth-child(1) {
          margin: 0 10px;
          font-size: 13px;
        }

        img {
          width: 8vw;
        }
      }
    }

    .content {
      width: 30vw;
      // height: 100vh;
      flex-direction: column;
      display: flex;
      align-items: center;
      padding: 0 15px;

      .logo {
        margin-top: 20px;

        img {
          width: 18vw;
        }
      }

      .tab-header-login {
        margin-bottom: 30px;

        li {
          width: auto;

          a {
            text-decoration: none;
          }
        }
      }

      .form-wrapper {
        width: 100%;
        padding: 20px;
        border-radius: 20px;

        .title {
          text-align: center;
          margin: 25px 0;

          span:first-child {
            font-size: 3rem;
          }
        }

        &.center-element {
          padding-top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .input-wrapper {
          // height: 50%;
          display: flex;
          flex-direction: column;
          position: relative;
          margin: 10px 0;

          & span:nth-child(1) {
            font-size: 1.7rem;
            margin-bottom: 10px;
          }

          & span:nth-child(2) {
            font-size: 1.5rem;
          }

          label {
            margin-bottom: 6px;
            margin-left: 11px;
            font-size: 1.2rem;
            font-family: Mulish;
            font-weight: bold;
          }

          input {
            // height: 35px;
            border-radius: 5px;
            // padding: 0 15px 0 35px;

            outline: none;
          }

          .fa {
            position: absolute;
            bottom: 11px;
            left: 13px;
            color: $white-hover;
          }
        }

        .btn-wrapper {
          margin: 15px 0;

          button {
            // padding: 5px 30px;
            border: none;
            outline: none;
            background-color: #075eeb;
            color: $white;
            cursor: pointer;
            border-radius: 20px;
          }

          :disabled {
            background-color: #5e93eaf9;
            cursor: not-allowed;
          }
        }

        .capcha-wrapper {
          width: inherit;
          padding: 20px 0;

          span.error {
            color: rgb(250, 66, 66);
          }
        }

        .option-wrapper {
          display: flex;
          padding: 0 10px;
          justify-content: space-between;

          a {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }

    .right-content {
      width: 65vw;
      // height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background-image: url("/assets/img/asset-3.svg");
      background-repeat: no-repeat;
      background-size: 100%;

      & img:nth-child(1) {
        width: 30vw;
        display: flex;
      }

      .download {
        span {
          margin-bottom: 10px;
        }

        img {
          width: 60%;
          margin: 0 10px;
          cursor: pointer;
        }
      }

      .result-wrapper {
        // height: 100%;
        width: 100%;
        padding: 0px 30px 0px 10px;

        // overflow-y: scroll;
        // margin: 10% 0;
        hr {
          background-color: #eaeaea;
        }

        .tabs-header {
          border: none;
          width: 100%;
          margin-bottom: 15px;

          & .nav-item {
            margin: 0 4px;
            font-size: 15px;
            border: none;
            font-weight: 400;
            text-align: center;
            color: #323F4B;
            background-color: transparent;

            &.active {
              border-bottom: 3px solid #323F4B;
            }
          }
        }

        .wrapper-table {
          // height: 65vh;
          // min-height: 65vh;
          overflow-y: auto;

          table {
            width: 100%;
            background-color: #fff;
            color: $text-link-color;
            margin-bottom: 0;

            tr {
              font-size: .8rem;
            }
          }
        }

        // .remove-scroll {
        //   overflow-y: hidden;
        // }
      }
    }
  }
}

.wrapper-table {
  table {
    &.no-border {
      border: none;
    }

    td {
      border: none;

    }
  }
}