@import "../variables/variable";

.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $primary-color;
  box-sizing: border-box !important;
  &.bg-primary {
    background-color: $primary-color !important;
  }
  & .group-nav {
    // background-color: rosybrown;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // width: 200px;
    & .name-card-nav {
      left: 0;
      top: 0;
      width: 100%;
      height: fit-content;
      z-index: 80;
      display: none;
      position: absolute;
      text-align: center;
      & .name-card-nav-text {
        font-size: 12px;
        color: $white;
        padding: 15px;
        background-color: $primary-color;
      }
    }

    & .navbar-brand {
      display: none;
    }
    & .sub-nav-brand {
      color: $white;
      font-size: 20px;
    }
    & .navbar-toggler {
      color: $white;
      border: none;
      background-color: transparent;
      &:active {
        background-color: transparent;
        border: none;
      }
      &:focus {
        border: none;
        outline: none;
      }
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 130;
      }
      &.my-card-toggler {
        position: relative;
        z-index: 91;
      }
    }
    & .toggle-sidebar {
      font-size: 20px;
      color: $white;
      align-self: center;
      margin-right: 10px;
      // &.fa-times {
      //   color: $white-dark;
      // }
      // &.show-toggle {
      //   position: abosolute;
      //   z-index: 102;
      //   right: -200px;
      //   display: inline-block;
      // }
    }
    & .toggle-mini-sidebar {
      display: none;
    }
    & .navbar-nav {
      & .active {
        & .nav-link {
          color: $white-dark;
        }
      }
      & .nav-item {
        margin: 0 10px;
        position: relative;
        & .nav-link {
          font-size: 17px;
          color: $white;
          // & .badge-nav {
          // position: absolute;
          // left: 8px;
          // top: 3px;
          // margin-bottom: 10px;
          // background-color: $white;
          // font-size: 12px;
          // color: $primary-color;
          // width: 20px;
          // height: 18px;
          // border-radius: 40%;
          // text-align: center;
          // font-weight: bold;
          // display: inline-block;
          // }
          & img {
            width: 30px;
            height: 30px;
          }
        }
        & a:hover {
          color: $white-dark;
        }
      }
    }
    & .new-navbar-link {
      // background-color: salmon;
      // margin-right: -60px;
      & .new-navbar-link-wrapper {
        // width: 100px;
        margin: auto;
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-left: 0;
        & .new-navbar-list {
          // background-color: seagreen;
          position: relative;
          margin-left: 15px;
          align-self: center;
          & .new-navbar-link-item {
            color: $white;
            font-size: 18px;
            & .badge-nav {
              position: absolute;
              background-color: $white;
              color: $primary-color;
              width: 23px;
              height: 18px;
              top: -6px;
              left: 3px;
              line-height: 17px;
              text-align: center;
              border-radius: 10px;
              font-size: 13px;
              font-weight: bold;
            }
            &::before {
              content: " ";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 130;
            }
          }
          & .notif {
            background-color: white;
            color: $text-color-dark;
            width: 240px;
            top: 40px;
            left: -130px;
            // position: absolute;
            z-index: 136;
            border-radius: 6px;
            box-shadow: 0 5px 10px 3px $white-dark;
            & .notif-title,
            & .notif-footer {
              text-align: center;
              padding: 10px;
              font-size: 15px;
              // border-bottom: 1px solid $white-dark;
              color: $primary-color;
              & a {
                font-size: 13px;

                color: $primary-color;
              }
            }
            & .notif-list {
              list-style: none;
              padding: 0;
              border-top: 1px solid $white-dark;
              & li {
                & .notif-list-item {
                  // margin: -1px;
                  border: none;
                  padding: 4px 10px;
                  font-size: 14px;
                  width: 100%;
                  display: block;
                  text-decoration: none;
                  color: $text-color-dark;
                  border-bottom: 1px solid $white-dark;

                  &:hover {
                    background-color: darkcyan;
                  }
                  & .notif-title-item {
                    font-weight: 500;
                  }
                  & .notif-content-item {
                    font-size: 13px;
                  }
                  & .notif-date-item {
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding: 1px 0 0 0;
                    & span {
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
          & .profil {
            background-color: $white;
            width: 190px;
            top: 45px;
            left: -112px;
            z-index: 136;
            border-radius: 10px;
            box-shadow: 0 2px 5px 2px $white-dark;
            & .profil-list-link {
              list-style: none;
              padding-left: 0;
              & a {
                color: $text-color-dark;
                display: block;
                height: 100%;
                text-decoration: none;
                padding: 5px 15px;
                // border-bottom: 1px solid $white-dark;
                &:hover {
                  background-color: $link-side-bgcolor;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

// laptop view
@media (min-width: 1024px) {
  .navbar {
    top: 0;
    margin-left: 250px;
    // height: 57px;
    height: 8vh;
    &.bg-primary {
      background-color: $primary-color !important;
    }
    & .group-nav {
      & .name-card-nav {
        display: flex;
        position: relative;
        & .name-card-nav-text {
          font-size: 15px;
          padding: 10px;
        }
        // background-color: transparent;
      }
      & .toggle-sidebar {
        display: none;
      }
      & .toggle-mini-sidebar {
        display: block;
        font-size: 20px;
        color: $white;
      }
      & .sub-nav-brand {
        color: $white;
        font-size: 20px;
        margin: auto;
      }
      & .navbar-brand {
        display: block;
        &:hover {
          color: $white;
          font-size: 20px;
        }
        & i {
          color: $white;
        }
      }
      & .new-navbar-link {
        // margin: 10px 0;
        // background-color: salmon;
        margin: 0 15px;
        & .new-navbar-link-wrapper {
          list-style: none;
          padding-top: 2px;
          padding-left: 0;
          margin: 5px;
          display: flex;
          flex-direction: row;
          & .new-navbar-list {
            margin: 0 10px;
            position: relative;

            & .new-navbar-link-item {
              display: inline-block;
              width: 30px;
              height: 100%;
              font-size: 22px;
              color: $white;
              // background-color: rebeccapurple;
              &::before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 130;
              }
              & .badge-nav {
                width: 23px;
                height: 17px;
                margin-top: 2px;
                left: 10px;
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                line-height: 16px;
                border-radius: 10px;

                color: $white;
                position: absolute;
                background-color: red;
                line-height: 17px;
              }
            }
            & .notif {
              // opacity: 0;
              // overflow: hidden;
              width: 270px;
              // height: 200px;
              z-index: 140;
              background-color: $white;
              top: 48px;
              left: -230px;
              border-radius: 8px;
              box-shadow: 0 5px 10px 3px $white-dark;
              & .notif-title,
              .notif-footer {
                font-size: 14px;
                padding: 10px;
                font-weight: 500;
                text-align: center;
                color: $primary-color;
                & a {
                  text-decoration: none;
                }
              }
              & .notif-list {
                list-style: none;
                padding: 0;
                border-top: 1px solid $white-dark;
                border-bottom: 1px solid $white-dark;
                & .notif-list-item {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding: 5px 10px;
                  color: $text-color-dark;
                  text-decoration: none;
                  &:hover {
                    background-color: $white-dark !important;
                  }
                  & .notif-title-item {
                    font-size: 13px;
                    font-weight: 600;
                  }
                  & .notif-content-item {
                    font-size: 12px;
                  }
                  & .notif-date-item {
                    padding-top: 2px;
                    text-align: justify;
                    font-size: 10px;
                    display: flex;
                    justify-content: space-between;
                  }
                }
              }
            }
            & .img-navlink {
              align-self: center;
              display: flex;
              justify-content: center;
              & img {
                width: 30px;
                height: 30px;
              }
            }
            & .profil {
              width: 160px;
              background-color: $white;
              left: -110px;
              top: 48px;
              z-index: 140;
              border-radius: 4px;
              box-shadow: 0 3px 8px 1px $white-dark;
              & ul {
                padding: 7px 0;
                list-style: none;
                padding-left: 0;
                & li {
                  a {
                    text-decoration: none;
                    color: $text-color-dark;
                    display: inline-block;
                    padding: 5px 15px;
                    width: 100%;
                    height: 100%;
                    font-size: 14px;
                    &:hover {
                      background-color: $link-side-bgcolor;
                    }
                  }
                }
              }
            }
          }
        }
      }
      & .navbar-nav {
        & .active {
          & .nav-link {
            color: $white-dark;
          }
        }
        & .nav-item {
          margin: 0 10px;
          position: relative;
          & .nav-link {
            position: relative;
            color: $white;
            // z-index: 9999999;
            font-size: 17px;
            & .badge-nav {
              left: 13px;
              top: 3px;
              margin-bottom: 10px;
              background-color: $white;
              font-size: 12px;
              color: $primary-color;
              width: 20px;
              height: 18px;
              border-radius: 40%;
              text-align: center;
              font-weight: bold;
              position: absolute;
              // display: inline-block;
              z-index: 120;
            }
            & img {
              width: 28px;
              height: 28px;
            }
            &::before {
              content: " ";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;

              z-index: 130;
              // background-color: rgba(238, 16, 238, 0.667);
            }
            &:visited,
            :active,
            :hover {
              color: $white;
            }
          }
          // & a:hover {
          //   color: $white-dark;
          // }

          // & .profil {
          // }
        }
      }
    }
  }
  .notif-page-list {
    font-family: "Nunito";
    font-size: 13px;
    color: #464444;
  }
}

@keyframes changeWidth {
  0% {
    height: 350px;
  }
  25% {
    height: 255px;
  }
  50% {
    height: 170px;
  }
  75% {
    height: 85px;
  }
  100% {
    height: 0;
  }
}
