@import "./variables/variable";
@import "./header/navbar";
@import "./header/sub-navbar";
@import "./sidebar/sidebar";
@import "./content/content";
@import "./footer/footer";
@import "./content/modal";
@import "./content/card";
@import "./content/tabs";

body {
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  font-family: "Nunito", sans-serif;
  margin-top: 0;
  padding-top: 0;
  box-sizing: border-box !important;
  // position: fixed;
  // overflow: hidden;
  // &.body-content {
  //   display: inline-block;
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   z-index: 102;
  //   background-color: rgba(0, 0, 0, 0.39);
  // }
}

.body {
  &.fixed {
    position: fixed;
  }
}

.text-warn {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.527);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  font-size: x-large;
  z-index: 120;
}

.show-dark {
  &::after {
    content: " ";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.527);
    z-index: 99;
    position: fixed;
  }
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: "#fff";

  &:hover {
    outline: none;
    border: 1px solid $link-active;
    box-shadow: none;
    background-color: $link-active;
  }

  &:disabled {
    outline: none;
    border: 1px solid $link-active;
    box-shadow: none;
    background-color: $link-active;
  }

  &:active {
    outline: none;
    border-color: $link-active !important;
    box-shadow: none !important;
    background-color: $link-active !important;
  }
}

.btn {

  &:focus,
  &:visited {
    box-shadow: none;
    outline: none;
    border: none;
  }

  &:active {
    box-shadow: none;
    outline: none;
    border: none;
  }
}

.pointer-mouse {
  cursor: pointer;

  &:hover {
    color: $white-hover !important;
  }
}

.active-selected {
  background-color: rgba($color: $primary-color, $alpha: 0.5) !important;
  color: $white-dark;

  &:hover {
    color: $white-dark;
    background-color: rgba($color: $primary-color, $alpha: 0.5) !important;
  }
}

.image-asign-list {
  margin: 5px 0;
}

.display-flex {
  display: flex;
  flex-direction: row;
}

.m-0-10 {
  margin: 0 10px;
}

.m-lr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-tb-20 {
  margin: 20px 0px;
}

.p-10 {
  padding: 10px;
}

.p-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.font-12 {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
}

.red {
  color: red;
}

.form-control {
  &:focus {
    box-shadow: none;
    border-color: $link-active;
  }
}



// .modal-content {
//   width: 60vw !important;
// }

.unread-msg {
  background-color: #dcdcdc;
}

.expand-navbar,
.expand-sub-navbar,
.expand-content {
  margin-left: 80px;
}

.my-dropdown {
  display: none;
  position: absolute;
}

.line-vertical {
  display: none;
}

span {
  &.badge {
    padding: 5px 8px;
    border-radius: 10px;
    font-weight: 400;
  }
}

.pointer {
  cursor: pointer;
  font-size: 20px;
}

.fa-toggle-on {
  color: #3f5fec;
}

.text-grey {
  color: #6c757d;
}

.display-list-bio {
  display: flex;
  flex-direction: column;
}

.multiple-input {

  &-form {
    border-radius: 5px;
    position: relative;
    border: 1px solid #6c757d;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    // &__value {
    //   flex-wrap: wrap;
    //   bottom: 0;
    //   width: fit-content;
    //   display: flex;
    //   flex-direction: row;
    //   background-color: seagreen;
    //   align-items: center;
    // }
    // display: inline-block;
    span {

      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // white-space: nowrap;
      background-color: $info-color-transparent;
      padding: 0 0 0 5px;
      border-radius: 3px;
      color: $white;
      font-size: 14px;
      margin: 3px;

      i {
        margin: 0 5px;
        cursor: pointer;
      }
    }

    .form-control {
      // height: 20px;
      width: 100px;
      border: none;
      flex: 1;
      // background-color: sandybrown;
    }
  }

  .multiple-info {
    font-size: 11px;
    color: rgb(237, 97, 97);
  }
}

.justify-text {
  text-align: justify;
}

.row {
  &.reset-row {
    margin-left: 0;
    margin-right: 0;
  }
}

.parent-list {
  list-style: none;
  padding-left: 0;
  margin: 1px 0;

  .child-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px;
    align-items: center;
    border-bottom: 1px solid $white-dark;
  }

  .fa {
    color: $white-hover;
    cursor: pointer;
  }
}

.notifConnection {
  position: fixed;
  border-radius: 10px;
  top: 5%;
  padding: 10px;
  text-align: center;
  color: $white-dark;
  left: 50%;
  font-size: 15px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 999;
  width: 50vw;
  background-color: rgba(250, 68, 48, 0.957);
}

.box-input {
  border: 1px solid $white-dark;
  min-height: 40px;
  border-radius: 5px;
  line-height: 40px;
  padding: 0 5px;
  position: relative;

  span {
    margin: 0 5px;
    padding: 0 5px;
    border-radius: 3px;
    background-color: $white-dark;
  }
}

.box-input-with-list {
  border: 1px solid $white-dark;
  min-height: 40px;
  border-radius: 5px;
  padding: 0 5px;
  position: relative;

  span {
    margin: 0 5px;
    padding: 0 5px;
    border-radius: 3px;
    background-color: $white-dark;
  }
}

.input-group-ico {
  position: relative;
  margin: 0 3px;
  z-index: 1;

  input {
    padding-left: 25px;
  }

  .fa {
    position: absolute;
    top: 10px;
    left: 2%;
    z-index: 1;
  }
}

// .form-group .react-datepicker-wrapper {
// width: inherit;
// }

.form-group .react-datepicker-popper {
  width: 60vw;
  z-index: 9999;
}

.w-100 {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.editable-form {
  input {
    margin-right: 10px;
    background-color: $white-dark;
    padding: 2px 8px;
    border: none;
    border-bottom: 1.5px solid $primary-color;
    width: 250px;
    outline: none;
    color: $text-link-color;
    background-color: transparent;

    &:disabled {
      background-color: transparent;
      border: none;
    }

    &:focus {
      border: none;
      outline: none;
      border-bottom: 1.5px solid $primary-color;
      background-color: transparent;
    }
  }

  .react-datepicker__tab-loop {
    display: inline-block;
  }

  .react-datepicker-wrapper {
    width: 300px;
  }

  .fa {
    color: $primary-color;
    cursor: pointer;
  }
}

// .form-group .react-datepicker-wrapper + .fa {
//   padding-left: 5px;
// }

@media (min-width: 1024px) {
  .line-vertical {
    position: relative;
    height: 80%;
    border-right: 2px solid $white-dark;
    display: flex;
  }

  .line-horizontal {
    border-bottom: 1px solid #eaeaea;
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
  }

  .display-list-bio {
    display: flex;
    flex-direction: row;

    & span:nth-child(1) {
      width: 30%;
    }

    & span:nth-child(2) {
      width: 70%;
    }
  }

  .alert-pop-up {
    position: absolute;
    z-index: 99999;
    top: 80%;
    right: 2%;
    // display: none;
    background-color: salmon;
    width: fit-content;
    height: 20px;
    height: 40%;

    div {
      padding: 10px;
      background-color: rgb(117, 236, 143);
      border-radius: 5px;
      color: #fff;
      // top: 90%;
    }
  }

  .show-alert {
    display: inline-block;
    animation: 1s linear animasi_alert;
  }

  .unread-msg {
    background-color: hsla(226, 63%, 19%, 0.329) !important;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .v-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .no-list {
    list-style: none;
    padding-left: 0;

    li {
      padding: 10px;
      background-color: #dad8d8;
      margin: 3px 0px;
      border-radius: 5px;
    }

    .list-style {
      border-radius: 5px;
      border: 1.5px solid #dad8d8;
      background-color: white;

      li {
        font-size: large;
        padding: 7px 0;
        margin: 0;
        background-color: white;

        label {
          padding-left: 10px;
          margin-bottom: 0;

          i {
            font-size: 1rem !important;
            margin-right: 5px !important;
          }
        }
      }
    }
  }

  .wrapping-element {
    // word-wrap: inherit;
    // display: flex;
    white-space: pre;
    width: 200px;
  }

  .inline-display {
    background-color: #3fa445;
    margin-right: 5px;
    padding: 0 4px;
    color: $white-dark;
    border-radius: 5px;
    display: inline-block !important;
  }

  .box-wrapper {
    margin: 20px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    .box-info {
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $white-dark;
      position: relative;

      .title {
        font-size: 16px;
      }

      span {
        font-size: 12px;
        color: $white-dark;
      }

      .value {
        font-size: 22px;
        font-weight: 600;
      }

      .fa {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 19px;
        padding: 10px;
        background-color: $primary-color;
        color: #fff;
        border-radius: 50%;
      }
    }
  }

  .chart-wrapper {
    border: 1px solid $white-dark;
    border-radius: 10px;
    padding: 10px;
  }

  .my-chart {
    width: 100%;

    .my-chart-body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 300px;
    }
  }

  .input-error {
    border: 1px solid red;

    &:focus {
      border: 1px solid red;
    }
  }

  .input-success {
    border: 1px solid rgb(29, 238, 39);

    &:focus {
      border: 1px solid rgb(29, 238, 39);
    }
  }

  @media print {

    html,
    body {
      // height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @keyframes animasi_alert {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}