$primary-color: #121d50;
$white: #fff;
$white-hover: rgb(155, 150, 150);
$white-dark: #e2e2e2;
// $white-dark: rgb(194, 190, 190);
$text-link-color: #3d3f56;
$link-side-color: #2e2d2d;
$link-side-bgcolor: #121d50;
$info-color: #007BFF;
$info-color-transparent: rgba(0, 123, 255, 0.707);
$link-active: #121d50;

$text-color-dark: #464444;
